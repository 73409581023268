<template>
  <div id="left-side">
    <div id='padding-top'> </div>
    <div id='left-side'>
      <div class="left-side-content">
        <div class="left-side-content-top">
          <img src="../assets/dasca.jpeg" alt="logo" id="logo" style="border-radius: 50%;max-width:85%;max-height:85%;">
          <h1>Stefan Dascalescu</h1>
          <h2 v-if="language == 'EN'">Private Tutor</h2>
          <h2 v-else> Meditator </h2>
        </div>
      </div>
      <div id="social-media">
        <div class="wrapper">
          <a href="https://www.facebook.com/stefan.dascalescu.2013/" class="icon facebook">
            <div class="tooltip"> Facebook </div>
            <span><i class="fab fa-facebook-f"></i></span>
          </a>
          <a href="https://www.linkedin.com/in/stefdasca/" class="icon twitter">
            <div class="tooltip">Linkedin</div>
            <span><i class="fab fa-linkedin"></i></span>
          </a>
          <a href="https://github.com/stefdasca/" class="icon github">
            <div class="tooltip">Github</div>
            <span><i class="fab fa-github"></i></span>
          </a>
          <a href="https://www.youtube.com/@stefdasca/ " class="icon youtube">
            <div class="tooltip">Youtube</div>
            <span><i class="fab fa-youtube"></i></span>
          </a>
        </div>
      </div>
      <div
        style="text-align: left; padding-left: 5%; font-size: 20px; border-left: 4px solid #2a9d8f; color: #fff; font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;">
        <p><strong>Mail:</strong> <a href="mailto:stefdasca@gmail.com"
            style="color: #333333; text-decoration: none;"><strong>stefdasca@gmail.com</strong></a><br>
          <strong>Whatsapp:</strong> <a href="https://wa.me/+40753383441"
            style="color: #333333; text-decoration: none;"><strong>+40 753 383 441</strong></a><br>
          <strong>Discord:</strong> <strong style="color: #333333;">stefdasca</strong>
        </p>
      </div>
    </div>
  </div>
</template>


<script>
export default {
  name: 'LeftSide',
  props: {
    language: String,
  }
}

</script>


<style>
.wrapper .icon {
  position: relative;
  background-color: #ffffff;
  border-radius: 50%;
  margin: 10px;
  width: 50px;
  height: 50px;
  line-height: 50px;
  font-size: 22px;
  display: inline-block;
  align-items: center;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  color: #333;
  text-decoration: none;
}

.wrapper .tooltip {
  position: absolute;
  top: 0;
  line-height: 1.5;
  font-size: 14px;
  background-color: #ffffff;
  color: #ffffff;
  padding: 5px 8px;
  border-radius: 5px;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
  opacity: 0;
  pointer-events: none;
  transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.wrapper .tooltip::before {
  position: absolute;
  content: "";
  height: 8px;
  width: 8px;
  background-color: #ffffff;
  bottom: -3px;
  left: 50%;
  transform: translate(-50%) rotate(45deg);
  transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.wrapper .icon:hover .tooltip {
  top: -45px;
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
}

.wrapper .icon:hover span,
.wrapper .icon:hover .tooltip {
  text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.1);
}

.wrapper .facebook:hover,
.wrapper .facebook:hover .tooltip,
.wrapper .facebook:hover .tooltip::before {
  background-color: #3b5999;
  color: #ffffff;
}

.wrapper .twitter:hover,
.wrapper .twitter:hover .tooltip,
.wrapper .twitter:hover .tooltip::before {
  background-color: #46c1f6;
  color: #ffffff;
}

.wrapper .instagram:hover,
.wrapper .instagram:hover .tooltip,
.wrapper .instagram:hover .tooltip::before {
  background-color: #e1306c;
  color: #ffffff;
}

.wrapper .github:hover,
.wrapper .github:hover .tooltip,
.wrapper .github:hover .tooltip::before {
  background-color: #333333;
  color: #ffffff;
}

.wrapper .youtube:hover,
.wrapper .youtube:hover .tooltip,
.wrapper .youtube:hover .tooltip::before {
  background-color: #de463b;
  color: #ffffff;
}</style>