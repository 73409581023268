<template>
  <div>
    <Navbar :changeLanguage = "changeLanguage" :changePage = "changePage" :lang = "lang"></Navbar>
    <LeftSide class = "position-left" :language = "lang"></LeftSide>
    <RightSide class = "position-right" :page = "page" :lang="lang" :changepage = "changePage" ></RightSide>
  </div>
</template>

<script>
import Navbar from './Navbar.vue';
import LeftSide from './LeftSide.vue';
import RightSide from "./RightSide.vue";
export default {
  name: 'HelloWorld',
  props: {
  },
  data() {
    return {
      lang: "EN",
      page: "about"
    }
  },
  components: {
    Navbar,
    LeftSide,
    RightSide
  },
  // have an onmounted to test if we have saved for refresh the language
  methods: {
    changeLanguage(lang) {
      this.lang = lang;
    },
    changePage(page) {
      this.page = page;
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.position-left
{
  position: absolute;
  left: 0;
  top: 50px;
  bottom: 0;
  width: 25%;
  background-color: #FF8400;
  color: white;
  text-align: center;
  padding-top: 20px;
}
@media screen and (max-width: 600px) {
  .position-left {
    display: none;
  }
  .position-right {
    left: 0%;
  }
}
.position-right {
  position: absolute;
  left: 25%;
  top: 50px;
  background-color: white;
  color: black;
  text-align: center;
  padding-top: 20px;
}
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
