/* eslint-disable */
<template>
    <div id="right-side" :style="{
        marginTop: (page == 'about' || page == 'tutoring' ? '2%' : '5%'),
        width: (page == 'about' || page == 'tutoring' ? '50%' : '80%'),
        marginLeft: (page == 'about' || page == 'tutoring' ? '12%' : '0%')
    }">
        <div v-if="page == 'about'">
            <div v-if="lang == 'RO'" class="middle" style="font-size: large;">
                Buna ziua, sunt Stefan si de-a lungul ultimilor 5 ani, am devenit un antrenor profesionist pentru olimpiada
                de informatica, algoritmi si structuri de date care a ajutat foarte multi elevi sa obtina rezultate de top
                la olimpiade si concursuri de programare precum <strong>IOI, USACO, ICPC, diferite Olimpiade Nationale
                </strong> precum si numeroase alte competitii de programare. <a @click="changepage('resume')"
                    class="custom-link" style="text-decoration: none;"> <b> Puteti vedea lista completa aici. </b></a>
                <br /><br />
                Am absolvit Facultatea de Matematica si Informatica a Universitatii Bucuresti, fiind membru in seria de
                olimpici. Inca de cand am descoperit informatica am avut o pasiune ce m-a ajutat sa ma autodepasesc si sa
                obtin rezultate tot mai bune de la an la an atat la olimpiadele scolare cat si acum, in calitate de profesor
                indrumator.<br /><br />
                Activitatea mea a inceput in clasa a XI-a, cand am decis sa incep un demers voluntar la liceul unde invatam,
                impartasindu-mi cunostintele cu colegii mai mici, ajutandu-i sa obtina rezultate mai bune la competitiile de
                informatica. In ciuda faptului ca pandemia a pus pauza acestor eforturi, experienta capatata m-a ajutat sa
                incep activitatea de mentor la un nivel profesionist, activitate pe care o fac si in prezent, serviciile
                mele fiind folosite de elevi si studenti din peste 20 tari de pe toate continentele, adaptandu-ma cu
                usurinta la diversele provocari adunate de-a lungul anilor.
                <br /> <br />

                In prezent, in afara de activitatile legate de indrumarea elevilor spre succes, sunt un membru activ al
                comunitatilor internationale de algoritmica, fiind implicat in proiecte atat in Romania cat si in
                strainatate. <br /><br />
                Printre altele, cateva din cele mai importante proiecte la care iau parte sunt:<br /><br />
                <li class="left-align">fondatorul serverului de discord <a href="https://discord.gg/roalgo"
                        style="text-decoration: none;"><b>RoAlgo</b></a> - cea mai mare comunitate de informatica si
                    programare competitiva din Romania, un loc ce a adunat peste 2000 de membri si a organizat peste 10
                    concursuri, a incarcat mii de probleme istorice romanesti precum si multe alte proiecte interesante sub
                    coordonarea si indrumarea mea.</li>
                <li class="left-align">coordonatorul comisiei romanesti a <a href="https://iio.team/"
                        style="text-decoration: none;"> <b>Olimpiadei Internationale de Informatica pe Echipe</b></a></li>
                <li class="left-align">membru in consiliul director SEPI - Societatea pentru Excelenta si Performanta in
                    Informatica, organizatia responsabila cu organizarea olimpiadei de informatica in Romania</li>
                <li class="left-align">membru in comisiile stiintifice la olimpiada de informatica din Romania</li>
                <li class="left-align">coordonator a peste <a href="https://kilonova.ro/problem_lists/464"
                        style="text-decoration: none"><b> 10 concursuri romanesti </b></a> si <a
                        href="https://codeforces.com/gym/104782" style="text-decoration: none;"><b> internationale </b></a>
                    organizate de numerosi elevi, studenti si alti autori de probleme</li>
            </div>
            <div v-else class="middle" style="font-size: large;">
                Hello, I am Stefan and over the last <strong>5 years</strong>, I have been a professional Olympiad, data
                structures and algorithms coach who has helped many students achieve top results at Olympiads and
                programming competitions such as <strong>IOI, USACO, ICPC, several National Olympiads </strong> and various
                other programming competitions. <a @click="changepage('resume')" class="custom-link"
                    style="text-decoration: none;"> <b>Check
                        them out.</b></a>
                <br /><br />
                I graduated from the Faculty of Mathematics and Computer Science, University of Bucharest, after having been
                a member of the Olympics' class. Ever since I discovered computer science I developed a passion which helped
                me become better and better, year by year, both during my Olympiad years and now, since I started teaching.
                <br /><br />
                My first tutoring activity started in the 11th grade, when I decided to start holding computer science
                classes at my school on a voluntary basis, sharing my knowledge with my younger schoolmates, helping them
                achieve better results at the Informatics competitions. Even though the pandemic put a break to these
                efforts, this experience helped me begin my tutoring activity at a professional level, an activity that
                continues to the present. My services have been used by students from over 20 countries, spanning all the
                continents, having successfully adapted to the various challenges that came over the years. <br /> <br />

                Nowadays, aside from the tutoring related activities, I am an active member of the competitive programming
                community, being involved in projects both in Romania and abroad. <br /><br />
                Among others, some of the most important projects I am part of nowadays are:<br /><br />
                <li class="left-align">founder of <a href="https://discord.gg/roalgo"
                        style="text-decoration: none;"><b>RoAlgo community</b></a> - Romania's largest algorithms and
                    competitive programming community, a community with over 2000 members which has organized several
                    contests, uploaded
                    thousands of new and old problems while also working on various exciting projects under my coordination
                    and leadership</li>
                <li class="left-align">coordinator of the Romanian scientific commitee of the <a href="https://iio.team/"
                        style="text-decoration: none;"> <b>International Informatics Olympiad in Teams</b></a></li>
                <li class="left-align">member in the director council of SEPI - Romania's Society of Excellence and
                    Performance in Informatics, the organization in charge of organizing everything related to Romanian
                    Olympiad in Informatics</li>
                <li class="left-align">member in the scientific committees of the Romanian Olympiad in Informatics</li>
                <li class="left-align">coordinator of more than <a href="https://kilonova.ro/problem_lists/464"
                        style="text-decoration: none"><b>10 Romanian</b></a> and <a href="https://codeforces.com/gym/104782"
                        style="text-decoration: none;"><b>international contests</b></a> organized by various students and
                    problemsetters</li>
            </div>
        </div>
        <div v-else-if="page == 'resume'">
            <div class="medal-display" v-if='lang == "EN"'>
                <h1>Students' results over my career</h1>

                <h2> USACO </h2>

                <div class="achievement-container">

                    <div class="achievement">
                        <div class="icon">🏆</div>
                        <h3>6 USACO Platinum Promotion</h3>
                        <p>Including a 7th grade student and a training camp finalist</p>
                    </div>

                    <div class="achievement">
                        <div class="icon">🥇</div>
                        <h3>11 USACO Gold Promotions</h3>
                        <p>Including 3 promoted students in 2023-24 season</p>
                    </div>

                    <div class="achievement">
                        <div class="icon">🥈</div>
                        <h3>8 USACO Silver Promotions</h3>
                        <p>Including 2 promoted students in 2023-24 season</p>
                    </div>

                </div>

                <h2> International Olympiads </h2>


                <div class="achievement-container" style='padding-top: 10px'>


                    <div class="achievement">
                        <div class="icon">🥇</div>
                        <h3>2 Gold Medals</h3>
                    </div>

                    <div class="achievement">
                        <div class="icon">🥈</div>
                        <h3>8 Silver Medals</h3>
                    </div>

                    <div class="achievement">
                        <div class="icon">🥉</div>
                        <h3>11 Bronze Medals</h3>
                    </div>

                </div>

                <h2> National Olympiads </h2>


                <div class="achievement-container" style='padding-top: 10px'>


                    <div class="achievement">
                        <div class="icon">🥇</div>
                        <h3>5 Gold Medals</h3>
                    </div>

                    <div class="achievement">
                        <div class="icon">🥈</div>
                        <h3>7 Silver Medals</h3>
                    </div>

                    <div class="achievement">
                        <div class="icon">🥉</div>
                        <h3>14 Bronze Medals</h3>
                    </div>

                </div>

            </div>
            <div class="medal-display" v-else>
                <h1>Rezultatele studentilor</h1>

                <h2> USACO </h2>

                <div class="achievement-container">

                    <div class="achievement">
                        <div class="icon">🏆</div>
                        <h3>6 elevi au promovat in USACO Platinum</h3>
                        <p>Including a 7th grade student and a training camp finalist</p>
                    </div>

                    <div class="achievement">
                        <div class="icon">🥇</div>
                        <h3>11 elevi au promovat in USACO Gold</h3>
                        <p>Including 3 promoted students in 2023-24 season</p>
                    </div>

                    <div class="achievement">
                        <div class="icon">🥈</div>
                        <h3>8 elevi au promovat in USACO Silver </h3>
                        <p>Including 2 promoted students in 2023-24 season</p>
                    </div>

                </div>

                <h2> Olimpiade Internationale </h2>


                <div class="achievement-container" style='padding-top: 10px'>


                    <div class="achievement">
                        <div class="icon">🥇</div>
                        <h3>2 Medalii de Aur</h3>
                    </div>

                    <div class="achievement">
                        <div class="icon">🥈</div>
                        <h3>8 Medalii de Argint</h3>
                    </div>

                    <div class="achievement">
                        <div class="icon">🥉</div>
                        <h3>11 Medalii de Bronz</h3>
                    </div>

                </div>

                <h2> Olimpiade Nationale </h2>


                <div class="achievement-container" style='padding-top: 10px'>


                    <div class="achievement">
                        <div class="icon">🥇</div>
                        <h3>5 Medalii de Aur</h3>
                    </div>

                    <div class="achievement">
                        <div class="icon">🥈</div>
                        <h3>7 Medalii de Argint</h3>
                    </div>

                    <div class="achievement">
                        <div class="icon">🥉</div>
                        <h3>14 Medalii de Bronz</h3>
                    </div>

                </div>

            </div>
            <div v-if="lang == 'EN'" class="middle">
                <br>
                <h1>The complete list of my and my students' achievements</h1>
                <a class='big' style='padding-right: 50px; margin-top: 100px;'
                    href='https://drive.google.com/file/d/1C_-eoNBn3ZYz3Iqf6r4I1X3fbwKd7W7m/view'>Personal CV</a>
                <a class='big' href="https://drive.google.com/file/d/1PXk_X3R9SEmkDPNQtbCMoL9rrqrXu02C/view">Career
                    Results</a>
            </div>
            <div v-else>
                <br>
                <h1>Lista completa cu toate realizarile mele si ale studentilor mei</h1>
                <a class='big' style='padding-right: 50px; margin-top: 100px;'
                    href='https://drive.google.com/file/d/1C_-eoNBn3ZYz3Iqf6r4I1X3fbwKd7W7m/view'>CV Personal</a>
                <a class='big' href="https://drive.google.com/file/d/1PXk_X3R9SEmkDPNQtbCMoL9rrqrXu02C/view">Rezultatele
                    carierei</a>
            </div>
        </div>
        <div v-else-if="page == 'tutoring'">
            <div v-if="lang == 'RO'">
                Ofer cursuri <b>normale</b> si <b>premium</b> individuale si de grup pentru algoritmi, structuri de date si
                olimpiada de informatica. Daca vrei sa devii mai bun la <b>informatica si la rezolvat probleme</b> in timp
                ce obtii rezultate foarte bune pe parcurs, sunt persoana potrivita pentru a te ghida spre aceste obiective,
                lucru aratat de lunga mea experienta in domeniu, fiind un concurent activ de peste 10 ani si un mentor de
                peste 5 ani. Printre altele, ofer urmatoarele servicii: <br /><br />
                <li class="left-align">pregatire profesionista pentru <b>USACO, IOI, EJOI, olimpiada nationala din
                        Romania</b> si multe alte olimpiade si concursuri la informatica, adaptate la nevoile fiecarui elev
                </li>
                <li class="left-align">pregatire profesionista pentru <b> interviurile tehnice </b> de la companiile din
                    domeniul IT</li>
                <li class="left-align"><span class="new-badge">NOU</span> Pentru un pret foarte bun, puteti obtine acces la
                    teme trimise des fara sa fie nevoie sa va alaturati cursurilor</li>
                <li class="left-align">organizare de cursuri pentru diverse tabere de informatica, precum si predarea lor in
                    format fizic sau online</li>
                <li class="left-align">ajutor la intelegerea si aprofundarea materiilor de la facultatile de informatica
                </li>
                <br />
                Deoarece nu exista doi elevi identici, iar fiecare elev are nevoi diferite, si metodele mele de predare sunt
                versatile, adaptandu-se la nevoile fiecarui elev. Prin lectiile predate intr-o ordine logica, tinand cont de
                cunostintele pe care elevul le are deja, precum si prin temele ce contin probleme relevante, se maximizeaza
                sansa obtinerii celor mai bune rezultate la informatica. <br /> <br />
                <h3> Pentru a maximiza rezultatele obtinute de un elev, aplic urmatoarele tehnici, rafinate de-a lungul
                    anilor pe parcursul muncii mele cu cursanti din intreaga lume: </h3>
                <li class="left-align"><b>programa adaptata</b> si structurata la nevoile fiecarui elev, asigurandu-se un
                    proces gradual si rapid de intelegere si deprindere a informatiilor, sub indrumarea mea/li>
                <li class="left-align">utilizarea celor mai relevante si utile probleme date la Olimpiadele si concursurile
                    de informatica, punand cu prioritate cele mai relevante continuturi pentru fiecare elev</li>
                <li class="left-align">analiza amanuntita a performantelor din concursuri, combinata cu cele mai potrivite
                    sfaturi pentru a se asigura obtinerea celor mai bune rezultate posibile la fiecare concurs</li>
                <li class="left-align"><span class="new-badge">NOU</span> o <b>baza de date cu peste 3000 de probleme date
                        la Olimpiade</b>, grupate pe 50 de nivele de dificultate si impartite pe categorii in functie de
                    cunostintele necesare pentru a rezolva problema, asigurandu-se crearea celor mai relevante si utile teme
                    pentru crestere rapida a abilitatilor</li>
                <li class="left-align">pentru elevii ce se alatura lectiilor premium, <b>rezolvarea neclaritatilor dintre
                        lectii, </b> statutul meu de antrenor full time permitandu-mi sa pot raspunde la intrebari in cel
                    mult <b>16 ore</b> de cand au fost adresate</li>
                <br />
            </div>
            <div v-else>
                I offer <b>normal</b> and <b>premium</b> individual and group coaching for algorithms, data structures and
                Olympiads in Informatics. If you want to become better at <b>problem solving</b> while also achieving great
                results along the way, I am the right person to guide you towards your goals, fact that can be proven by my
                long experience in the algorithms and data structures, of over 10 years as a contestant and of over 5 years
                as a teacher. Among others, I offer the following services: <br /><br />
                <li class="left-align">professional tutoring for <b>USACO, IOI, EJOI, Romanian OI</b> and many other
                    olympiads of informatics, and contests in informatics and algorithmics, adapted to each student's needs
                </li>
                <li class="left-align">professional tutoring for <b>technical interviews</b> in the IT industry</li>
                <li class="left-align"><span class="new-badge">NEW</span> For a more affordable price, you can have access
                    to regular homeworks sent by me without having to join the full classes program</li>
                <li class="left-align">organizing syllabus and courses for various informatics camps, as well as teaching
                    the contents either online or in person</li>
                <li class="left-align">professional help for getting a better and a deeper understanding of the classes from
                    the computer science departments of various universities</li>
                <br />
                Since no two students are identical, and every individual has different needs, I adapt my methods to cater
                to each student's needs. Through a syllabus which follows a clear path based on the knowledge the student
                already has, and also through assignments which contain the most relevant problems, the best possible
                preparation methods are used in order to maximize the chances of success. <br /> <br />
                <h3> In order to maximize the results a student can obtain, I apply the following techniques, refined over
                    the years as I worked with students around the world: </h3>
                <li class="left-align"><b>customized syllabus</b>, adapted and structured to each student's needs, thus
                    ensuring a smooth process of picking up the knowledge, under my guidance</li>
                <li class="left-align">the usage of the most relevant and useful problems from Olympiads and programming
                    contests, prioritizing the most relevant contests for each student</li>
                <li class="left-align">thorough analysis of contest performances, combined with the most suitable advice in
                    order to ensure the best possible results every time</li>
                <li class="left-align"><span class="new-badge">NEW</span> a <b>database with over 3000 Olympiad
                        problems</b>, grouped in 50 difficulty levels
                    and tagged based on the required knowledge to solve each of the problems, thus ensuring the creation of
                    the most relevant assignments in order to ensure the fastest possible skill growth</li>
                <li class="left-align">for premium individual students, <b>covering doubts </b>in between lessons, my full
                    time status as a tutor allowing me to answer questions within <b>16 hours</b> after they were asked</li>
                <br />
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: "RightSide",
    props: {
        page: String,
        lang: String,
        changepage: { type: Function }
    },
    data() {
        return {
            // This data could come from an API or local storage in real scenarios
            medals: [
                { contest: 'International Olympiads', counts: [2, 7, 7] },
                { contest: 'National Olympiads', counts: [1, 2, 2] },
                // Add more medals as needed
            ],
        };
    },
    changePageAction(page) {
        this.changePage(page);
    }
}
</script>

<style scoped>
.new-badge {
    background-color: #ff5722;
    /* Bright color for visibility */
    color: white;
    /* Contrast for readability */
    padding: 2px 5px;
    /* Spacing around the text */
    font-weight: bold;
    /* Make the text bold */
    border-radius: 1px;
    /* Rounded corners for a badge-like appearance */
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
    /* Subtle shadow for depth */
    transform: rotate(-5deg);
    /* Tilt the badge for dynamism */
    transition: transform 0.3s ease;
    /* Animation for hover effect */
}

.new-badge:hover {
    transform: rotate(0deg) scale(1.1);
    /* Slight increase in size and straighten on hover */
    cursor: pointer;
    /* Change cursor to indicate it's clickable or interactive */
}

.achievement-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
}

.achievement {
    background-color: #ffffff;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 20px;
    width: 300px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    text-align: center;
}

.achievement h3 {
    color: #333;
    margin-top: 0;
}

.achievement p {
    margin: 10px 0;
    color: #555;
}

.icon {
    font-size: 48px;
    margin-bottom: 10px;
    color: #4a7ebc;
}

.medal-display ul {
    list-style-type: none;
    padding: 0;
}

.medal-display li {
    margin: 10px 0;
    font-size: 1.2em;
}
</style>

<style scoped>
.big {
    font-size: xx-large;
    font-weight: 200;
}

.middle {
    text-align: center;
}

#right-side {
    width: 80%;
    margin-top: 20%;
    overflow-x: hidden;
    overflow-y: scroll;
    max-height: 80%;
    background-color: #F6F1E9;
}

/* This targets the scrollbar itself */
::-webkit-scrollbar {
    width: 8px;
    /* for horizontal scrollbars */
    height: 8px;
    /* for vertical scrollbars */
}

/* This targets the scrollbar track (the background) */
::-webkit-scrollbar-track {
    background: transparent;
    /* iOS scrollbars have a transparent background */
}

/* This targets the scrollbar handle */
::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, .2);
    /* Light grey, semi-transparent */
    border-radius: 4px;
    /* Rounded corners on the scrollbar handle */
}

/* Optional: dynamic styling when hovering over the scrollbar */
::-webkit-scrollbar-thumb:hover {
    background-color: rgba(0, 0, 0, .4);
    /* Darker grey when hovering */
}

#contact {
    border: none;
    cursor: pointer;
}

.left-align {
    text-align: left;
}

.custom-link {
    color: #000000;
    /* Initial color of the link */
    transition: color 0.3s ease;
    /* Smooth transition for the color change */
}

.custom-link:hover {
    color: #ff5722;
    /* Color of the link on hover */
}
</style>