<template>
    <div id='navbar'>        
        <div class="navbar-content">
            <ul>
                <h3 style = "margin-left: 5px; margin-right: 5px; font-weight: 5000; font-size: 30; cursor: pointer;" @click = "changePageAction('tutoring')">Stefan Dascalescu</h3>
                <div class="vl"></div>
                <li v-if = "lang == 'EN'"><button class="but" @click = "changePageAction('resume')">Resume</button></li>
                <li v-else><button class="but" @click = "changePageAction('resume')">Rezultate</button></li>
                <li>|</li>
                <li v-if = "lang == 'EN'"><button class="but" @click = "changePageAction('about')">About</button></li>
                <li v-else><button class="but" @click = "changePageAction('about')">Despre mine</button></li>
                <li>|</li>
                <li v-if = "lang == 'EN'"><button class="but" @click = "changePageAction('tutoring')">Tutoring</button></li>
                <li v-else><button class="but" @click = "changePageAction('tutoring')">Predare</button></li>
            </ul>

            <select name="language-picker-select" id="language-picker-select">
                <option lang="en" value="EN" selected>English</option>
                <option lang="fr" value="RO">Romana</option>
            </select>
        </div>
    </div>
</template>

<script>

export default {
    name: 'NavBar',
    props: {
        changeLanguage: { type: Function },
        changePage: { type: Function },
        lang: String
    },
    mounted() {
        document.getElementById('language-picker-select').addEventListener('change', () => {
            let value = document.getElementById('language-picker-select').value;
            this.changeLanguage(value);
        });
    },
    methods: {
        changePageAction (page) {
            this.changePage(page);
        }
    }
}
</script>
  
<style scoped>
#navbar {
    width: 100%;
}

.navbar-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.navbar-content ul {
    display: flex;
    align-items: center;
    gap: 10px;
    list-style-type: none;
    margin: 0;
    margin-left: 5px;
    margin-top: -5px;
    padding: 0;
}

#language-picker-select {
    background-color: #FF8400;
    border: none;
    color: white;
    padding: 5px 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    margin-right: 5px;
}
.vl {
  border-left: 2px solid black;
  height: 40px;
}
.but {
    background-color: #F6F1E9;
    border: none;
    font-weight: 300;
    font-size: 350;
}
.but:hover {
    cursor: pointer;
    transition: ease 0.7s;
    background-color: #FF8400;
}

</style>
